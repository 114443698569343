(function toolsListener() {
  window.addEventListener("keydown", function (e) {
    if (e.keyCode === 9) {
      document.body.classList.remove("js-useMouse");
      document.body.classList.add("js-useKeyboard");
    }
  });

  window.addEventListener("mousedown", function (e) {
    document.body.classList.remove("js-useKeyboard");
    document.body.classList.add("js-useMouse");
  });
})();

(function toggleMenu() {
  const trigger = document.querySelector(".l-header-hamburger");
  const target = document.querySelector(".l-header-menu");
  trigger.onclick = () => {
    target.classList.toggle("js-menuOpened");
    trigger.classList.toggle("js-menuOpened");
  };
})();

(function particles() {
  let imgPaths = [
    "assets/img/logo_pc.svg",
    "assets/img/chart_pc.svg",
    "assets/img/brain_pc.svg",
    "assets/img/cube_pc.svg",
  ];
  let imgsPc = [];
  let imgsMobile = [];
  let breakpoint = 576;
  let slider = document.querySelector("#slider");

  imgPaths.forEach((imgPath) => {
    let imgPc = new Image();
    let imgMobile = new Image();

    imgPc.src = imgPath;
    imgMobile.src = imgPath.replace("_pc.svg", "_mobile.svg");

    imgsPc.push(imgPc);
    imgsMobile.push(imgMobile);
  });

  let ps = new ParticleSlider({
    width: window.innerWidth,
    height: window.innerHeight,
    sliderId: "slider",
    monochrome: false,
    showArrowControls: false,
    arrowPadding: 0,
    imgs: document.body.clientWidth > breakpoint ? imgsPc : imgsMobile,
    onSizeChange: (ps) => {
      ps.imgs = document.body.clientWidth > breakpoint ? imgsPc : imgsMobile;
      ps.init(true);
    },
  });

  let ptl = new ps.Particle(ps);
  ps.slideDelay = 0;

  const triggers = gsap.utils.toArray("[data-trigger]");

  triggers.forEach((trigger, index) => {
    ScrollTrigger.create({
      trigger,
      start: "top 70%",
      onEnter: () => {
        let positionY = 0;
        if (document.body.clientWidth > breakpoint) {
          positionY =
            trigger.offsetTop -
            (window.innerHeight - ps.imgs[index + 1].height) / 2 +
            trigger.offsetHeight / 2;

          if (index === 2) {
            positionY -= 160;
          } else {
            positionY -= 40;
          }
        } else {
          positionY =
            trigger.offsetTop - (ps.height - ps.imgs[index + 1].height) / 2;

          if (index === 0) {
            positionY += 40;
          }
        }
        slider.style.transform = `translateY(${positionY}px)`;
        ps.nextSlide(1);
      },
      onLeaveBack: () => {
        /** FIXME:帶修理 */
        let positionY = 0;
        if (document.body.clientWidth > breakpoint) {
          if (index === 0) {
            positionY = 0;
          } else {
            positionY =
              triggers[index - 1].offsetTop -
              (ps.height - ps.imgs[index].height) / 2 +
              triggers[index - 1].offsetHeight / 2 -
              40;
          }
        } else {
          if (index === 0) {
            positionY = 0;
          } else {
            positionY =
              triggers[index - 1].offsetTop -
              (ps.height - ps.imgs[index].height) / 2;

            if (index === 1) {
              positionY += 40;
            }
          }
        }
        slider.style.transform = `translateY(${positionY}px)`;
        ps.nextSlide(-1);
      },
    });
  });

  window.onresize = () => {
    ps.width = document.body.clientWidth;
    ps.height = window.innerHeight;

    let positionY = 0;
    let index = ps.currImg;

    if (index === 0) {
      positionY = 0;
    } else if (document.body.clientWidth > breakpoint) {
      positionY =
        triggers[index - 1].offsetTop -
        (ps.height - ps.imgs[index].height) / 2 +
        triggers[index - 1].offsetHeight / 2;

      if (index === 3) {
        positionY -= 180;
      } else {
        positionY -= 72;
      }
    } else {
      positionY =
        triggers[index - 1].offsetTop - (ps.height - ps.imgs[index].height) / 2;
    }
    slider.style.transform = `translateY(${positionY}px)`;
  };
})();

(function scrollAnchor() {
  const targets = gsap.utils.toArray(".l-header-menu a");
  targets.forEach((target) => {
    target.onclick = (e) => {
      e.preventDefault();
      document
        .querySelector(".l-header-hamburger")
        .classList.remove("js-menuOpened");
      document
        .querySelector(".l-header-menu")
        .classList.remove("js-menuOpened");
      gsap.to(window, {
        duration: 1.5,
        scrollTo: { y: target.getAttribute("href") },
        ease: "power1.inOut",
      });
    };
  });
})();
